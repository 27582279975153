import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import Hero from "../../components/hero";
import HorizontalTestimonial from "../../components/horizontal-testimonial";
import { StaticImage } from "gatsby-plugin-image";
import HeroEnd from "../../components/hero-end";

const GraphicDesignersPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}
				heroImg: file(relativePath: { eq: "16.jpg" }) {
					publicURL
					childImageSharp {
						fluid(quality: 100, maxWidth: 1920) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				daniel: file(
					relativePath: { eq: "testimonials/daniel-brookbank.jpg" }
				) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				richardW: file(
					relativePath: { eq: "testimonials/Richard Worsfold.jpg" }
				) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				lou: file(relativePath: { eq: "testimonials/lou.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				StuartG: file(
					relativePath: { eq: "testimonials/Stuart Gardiner.jpg" }
				) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				andrew: file(relativePath: { eq: "testimonials/selda-koydemir.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
			}
		`
	);
	const siteUrl = data.site.siteMetadata.siteUrl;
	const schemaImage = data.heroImg.publicURL;
	const title =
		"Graphic designers, Branding Consultants & Printers | Business & Executive Coaching in London";
	const description =
		"Graphic designers, branding consultants, and printers, frequently face challenges in expanding or scaling their own businesses effectively. As the creative industry continues to shift, it's so important to focus not only on the operational aspects of your business but also on your overall strategic growth.";

	const heroImage = data.heroImg.childImageSharp.fluid;
	const daniel = data.daniel.childImageSharp.gatsbyImageData;
	const andrew = data.andrew.childImageSharp.gatsbyImageData;
	const richardW = data.richardW.childImageSharp.gatsbyImageData;
	const lou = data.lou.childImageSharp.gatsbyImageData;
	const StuartG = data.StuartG.childImageSharp.gatsbyImageData;

	const ogImg = `${siteUrl}${schemaImage}`;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Industries",
				item: {
					url: `${siteUrl}/industries`,
					id: `${siteUrl}/industries`,
				},
			},
			{
				"@type": "ListItem",
				position: 3,
				name: "Graphic designers, Branding Consultants & Printers",
				item: {
					url: `${siteUrl}/industries/graphic-designers-branding-consultants-and-printers`,
					id: `${siteUrl}/industries/graphic-designers-branding-consultants-and-printers`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={title}
				description={description}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/industries/graphic-designers-branding-consultants-and-printers`,
					title: `${title}`,
					description: `${description}`,
					images: [
						{
							url: `${ogImg}`,
							width: 1920,
							height: 1080,
							alt: "Graphic designers, Branding Consultants & Printers Executive Coaching in London",
						},
					],
				}}
			/>
			<HeroEnd
				isDarken={true}
				backgroundImage={heroImage}
				title="Business & Executive Coach for Graphic designers, branding consultants & printers"
				description1=""
				description2=""
			/>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<p>
								Graphic designers, branding consultants, and printers,
								frequently face challenges in expanding or scaling their own
								businesses effectively. As the creative industry continues to
								shift, it's so important to focus not only on the operational
								aspects of your business but also on your overall strategic
								growth.
							</p>
							<p>
								A business and executive coach can be the pivotal resource you
								are looking for in your journey, providing the necessary
								insights to refine your business approach and utilise successful
								strategies. They guide you in prioritising actions that boost
								profitability and growth, helping you not only to respond to
								current market needs but also to adapt to future changes in a
								way that suits your business.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h3 className="text-primary pb-3">
								Empowering creative business with coaching
							</h3>
							<p>
								As a graphic designer, branding consultant, or printer,
								navigating the competitive creative industry can be challenging
								when looking to scale your business profitably. In our one to
								one business coaching sessions, we'll explore effective
								strategies such as identifying your niche market, innovating
								your product and service offerings, and refining your pricing
								strategies to boost profitability.
							</p>
							<p>
								With four decades of business expertise and more than seven
								years specialising in coaching, I can help you to overcome the
								obstacles unique to your creative sector. From my experience,
								graphic designers, branding consultants, and printers often face
								the difficult challenge of keeping up with design trends and
								evolving client demands while looking to grow their own firm
								sustainably.
							</p>
							<p>
								With a business coach, together we will craft a personalised
								plan that addresses your immediate challenges and establishes a
								solid foundation for the profitable growth and long-term success
								of your business.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<StaticImage
								className="float-lg-right mr-3 mb-3 ml-lg-3 service-page-body-image"
								src="../../images/diagram.jpg"
							/>
							<h3 className="text-primary pb-3">
								A Roadmap for taking your business to the next level
							</h3>

							<p>
								You're a graphic designer, branding consultant, or printer with
								the expertise to craft compelling visuals and deliver
								high-quality print materials, but when it comes to expanding
								your business profitably, what's your next move?
							</p>
							<p>Can you see yourself:</p>
							<ul className="pl-4">
								<li>
									Using niche marketing to attract and retain ideal clients.
								</li>
								<li>
									Innovating by introducing new services that complement your
									existing skills.
								</li>
								<li>
									Adjusting your pricing to truly reflect the value you bring to
									your clients.
								</li>
								<li>
									Strategically growing your team to support your business as it
									expands.
								</li>
							</ul>
							<p>
								The impact of these strategies can be substantial, transforming
								not just your business operations but also enhancing client
								relationships and your own job satisfaction.{" "}
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="bg-primary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">Why haven’t you started yet?</h2>
							<p>
								Struggling with balancing client projects and business growth?
								If you find it challenging to innovate, market your services
								effectively, or structure pricing for better margins, you are
								not alone.
							</p>
							<p>
								In the visually dynamic and competitive realm of graphic design
								and printing, it’s common to feel overwhelmed by day-to-day
								demands that leave you little room for your own strategic
								planning. Here’s how a dedicated Business & Executive Coach can
								support you:
							</p>
							<ul className="pl-4">
								<li>
									Receive guidance on how to broaden your service offerings and
									tap into new market segments effectively.
								</li>
								<li>
									Get help in analysing and refining your pricing models to
									boost your profitability.
								</li>
								<li>
									Gain support in enhancing your creative offerings or
									developing new products to keep ahead of industry trends.
								</li>
								<li>
									Learn strategies to attract, develop, and manage a team that
									supports both your business objectives and creative
									aspirations.
								</li>
								<li>
									Benefit from professional insights to elevate your creative
									services and overall business operations.
								</li>
							</ul>
							<p>
								Now is the perfect time to address these challenges and overcome
								your business barriers, with the help of an experienced,
								unbiased, and confidential business coach. Together, we can turn
								these obstacles into opportunities for your professional growth
								and business success.
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								size="lg"
								as={Link}
								to="/contact-us"
								className="cta-btn cta-btn-gtm"
							>
								Book a discovery meeting
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								How can coaching turn your business around?
							</h2>
							<p>
								As a business and executive coach specialising in assisting
								graphic designers, branding consultants, and printers, I
								understand the unique hurdles you encounter in expanding your
								business efficiently. While your expertise in visual
								communication and print media is unique, substantial growth is
								often achieved through strategic business development.
								Partnering with me ensures you receive personalised coaching
								sessions crafted to meet your specific needs.
							</p>
							<p>Our focus will include several vital areas:</p>
							<ul className="pl-4">
								<li>
									Personal and Professional Growth for Creative Professionals:
									Enhancing your skills and business acumen to stay competitive
									in the rapidly evolving design and print industry.
								</li>
								<li>
									Leadership and Team Development: Cultivating a team that
									matches the creativity and innovation of your projects,
									ensuring smooth execution and delivery.
								</li>
								<li>
									Strategic Business Planning: Developing strategies tailored to
									your unique market position and the specific goals of your
									creative business.
								</li>
								<li>
									Product and Service Innovation: Adapting your offerings to
									meet the changing demands of your clients and the market,
									ensuring relevance and competitiveness.
								</li>
							</ul>
							<p>
								My coaching approach offers bespoke guidance, providing a
								confidential space to explore new opportunities and tackle
								challenges head-on. This comprehensive support ensures that your
								business not only grows but sets a benchmark in the graphics,
								branding, and printing industry.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			{/* <section className="py-5">
				<Container>
					<Row>
						<Col>
							<StaticImage
								className="float-lg-left mr-3 mb-3 mb-lg-1 service-page-body-image"
								src="../../images/12.jpg"
							/>
							<h2 className="text-primary pb-3">Asking the right questions</h2>

							<p>
								As an executive coach, my job centers around asking the right
								questions and carefully listening to what you say, tapping into
								the true sentiment behind your words and your thought process.
							</p>
							<p>
								I learned how to REALLY listen to my clients in a
								non-judgemental way during my 5 years of counselling and
								psychotherapy training.
							</p>
							<p>
								Executive coaching is all about listening properly without
								judging, I will uncover and support you with any false
								assumptions, fears and life-limiting beliefs. These are the
								things that may be holding you back, like a lack of confidence,
								imposter syndrome etc.
							</p>
							<p>
								Executive coaching like this can help you manage these thoughts
								and feelings, so they no longer impact your personal or
								professional life, while working alongside you to gain focus and
								make a significant impact on your life and business.
							</p>
							<p>
								Empowering you to think about the reasons ‘why you can’, rather
								than ‘why you can’t.’
							</p>
							<p>
								Let’s decide what you want, make a plan and use coaching to make
								it happen!
							</p>
						</Col>
					</Row>
				</Container>
			</section> */}
			<section className="bg-tertiary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">About me as a business coach</h2>
							<p>
								With my extensive background in business management, an MBA, and
								a foundation in counselling, I understand the specialised
								challenges faced by graphic designers, branding consultants, and
								printers. It’s not only about producing high-quality designs and
								prints; it’s about strategically navigating your business
								towards profitable growth.
							</p>
							<p>
								In our coaching sessions, we'll create a secure environment
								where you can freely discuss everything from refining your
								creative vision and crafting actionable plans, to optimising
								your pricing strategy and broadening your service portfolio.
								We'll focus on identifying niche markets and innovating within
								your existing product lines.
							</p>
							<p>
								Are you ready to elevate your business to new heights? Let’s get
								started with business coaching for graphic designers, branding
								consultants, and printers.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Reviews from my business and executive coaching clients
							</h2>
						</Col>
					</Row>

					<HorizontalTestimonial
						name="Richard Worsfold. Branding Box."
						review="I have been working with Jason Cornes, a business coach, over the past six months. At the start of the process, I was time-poor and trying to juggle too many plates, some of which were not making any money. During the coaching process, Jason has helped me to identify my target market, develop client avatars, and refine my services to align with the needs of these client types. I have found the whole process extremely useful, and having Jason as a sounding board for ideas has been invaluable. Jason frequently pushes me on my decision-making and ensures I have looked at all of the options before making an informed decision. If you are looking for a business coach who is straight-talking and not afraid to point out areas of development and push you to improve, Jason is your coach!"
						linkedIn="https://www.linkedin.com/in/richardjamesworsfold/"
						profilePic={richardW}
					/>

					<HorizontalTestimonial
						name="Louise McIntyre. Printingprogress Ltd"
						review="Jason is a breath of fresh air and an incredibly talented business coach. If you are looking for someone to inspire you and help you to see how you can grow both your self-confidence and business then look no further. I have had many business coaches that work to a model that has never quite suited me or my business, but Jason is different he adapts his expertise and meets you exactly where he makes the most impact. Highly Recommended."
						linkedIn="https://www.linkedin.com/in/lou-mcintosh-712ab920"
						profilePic={lou}
					/>

					<HorizontalTestimonial
						name="Stuart Gardiner. Brass Tacks Design London Ltd"
						review="I approached Jason during a financially challenging period, seeking help to restructure my business. Over the course of six months, his impact has been profound. Thanks to his guidance, I have regained my confidence and ambition, and I've attracted more new clients than I ever imagined possible. Jason doesn't offer an easy ride; he challenges you and asks tough questions, which are crucial for identifying necessary changes in your business. His analytical approach, combined with years of real-world business experience, has provided invaluable insights and strategies. I highly recommend Jason to anyone looking to enhance profitability, sharpen their focus, and transform their mindset for the better!"
						linkedIn="https://www.linkedin.com/in/stuart-gardiner-42187016/"
						profilePic={StuartG}
					/>
				</Container>
			</section>
			{/* <section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h3 className="text-primary pb-3">
								Break the cycle with executive coaching in London
							</h3>
							<p>
								In this 24/7 world, leaders, senior managers, owners and
								executives are so busy that they don’t have the time to devote
								to their own development or to the development of the people who
								work for them. In other words, they’re caught in a vicious cycle
								of always “being busy.”
							</p>
							<p>
								In the same way, some struggle and worry about not being able to
								think clearly. You may have an idea, but you can’t seem to get a
								clear picture on what to do next, or perhaps you need to make
								decisions, but you don’t feel confident that the decision you
								make will be the right one.
							</p>
							<p>
								This is precisely why you need executive coaching services. As
								an executive coach in London, I can help you break the cycle of
								always being busy while giving you the confidence to make
								decisions that positively impact your life.
							</p>
						</Col>
					</Row>
				</Container>
			</section> */}
			{/* <section className="py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Ready to work towards that positive future?
							</h2>
							<p>
								Unfortunately, though, none of us have a crystal ball. I can’t
								tell you what life will be like if you do, take that job, wake
								up filled with confidence, or grow your business tenfold - but
								what I can tell you is...
							</p>
							<p>If you do nothing, nothing will change.</p>
							<p>
								On the other hand, it’s amazing what we can achieve together
								when we use coaching to focus on what you really want.
							</p>
							<p>
								So, with nothing to lose - why not book a introductory call with
								me?
							</p>
						</Col>
					</Row>
				</Container>
			</section> */}
			<section className="bg-secondary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h3 className="pb-3">Let's schedule a call together</h3>
							<p>
								Why not schedule a discovery call with me? It'll be a relaxed
								conversation where we can learn about each other and explore how
								personalised business and executive coaching might significantly
								benefit your business. This is your opportunity to not just
								reach, but surpass your business objectives. Let's seize this
								moment to grow and enhance your business together.
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								className="cta-btn cta-btn-gtm"
								size="lg"
								as={Link}
								to="/contact-us"
							>
								Get in Touch
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			{/* <CtaBanner
        headline="Executive Coaching"
        btnTxt="Book a call"
        btnLink="/contact-us"
        bgColor="light"
      /> */}
		</Layout>
	);
};
export default GraphicDesignersPage;
